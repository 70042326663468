@import '../../style/variables.scss';

.fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  &__label {
    color: $color-white;
    display: block;
    margin: 0 0 8px;
    font-size: 12px;
    text-align: left;
    line-height: 16px;
    letter-spacing: 0.32px;
  }

  &__label--zipcode {
    color: $color-white-variation-2;
    font-family: $font-family-secondary;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin-top: 8px;
  }

  &__sup {
    position: relative;
    top: 2px;
  }
}
