$font-family-primary: 'Ubuntu', sans-serif;
$font-family-secondary: 'Roboto', sans-serif;
$color-primary: #141414;
$color-primary-variation-1: #22262c;
$color-primary-variation-2: #464e59;
$color-secondary: #006adf;
$color-secondary-variation-1: #437af5;
$color-secondary-variation-2: #45bcff;
$color-secondary-variation-3: #a2ddff;
$color-secondary-variation-4: #99b8ed;
$color-secondary-variation-5: #f2f8ff;
$color-tertiary: #9a9a9a;
$color-tertiary-variation-1: #e5e5e5;
$color-tertiary-variation-2: #dee2e6;
$color-quaternary: #2d3648;
$color-white: #fff;
$color-white-variation-1: #e7e7e7;
$color-white-variation-2: #d6d6d6;
$color-blue: #003eb9;
$color-blue-variation-1: #bed6ff;
$color-cream: #f8f9fa;
$color-green: #24a148;
$color-green-variation-1: #42be65;
$color-green-variation-2: #8feca9;
$color-green-variation-3: #f8fefa;
$color-green-variation-4: #1ca743;
$color-black: #000;
$color-red-error: #ff8389;
$padding-content: 36px;
$mobile-large-breakpoint: 780px;
$mobile-medium-breakpoint: 650px;
$mobile-small-breakpoint: 440px;
$header-breakpoint: 900px;
