@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.share {
  position: relative;
  border-radius: 24px;
  width: 70px;
  height: 24px;

  &__btn {
    cursor: pointer;
    background: rgba(207, 207, 207, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    border-radius: 24px;
    width: 70px;
    height: 24px;
    border: 1px solid rgba(207, 207, 207, 0.3);

    &-text {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      transition: color 0.3s;
      color: $color-primary-variation-1;
    }

    svg path {
      transition: stroke 0.3s;
    }

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $color-primary;
        border: 1px solid rgba(207, 207, 207, 0.3);

        .share__btn-text {
          color: $color-white;
        }

        svg path {
          stroke: $color-white;
        }
      }
    }
  }
}
