@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.ad {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 8px;

    @include fadeInAnimation;

    animation-delay: 0.3s;
  }

  &__container {
    background-color: $color-white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.05);
    align-self: flex-start;

    @include fadeInAnimation;

    animation-delay: 0.3s;
  }

  &__unit,
  &__unit-preview {
    display: flex;
    justify-content: center;

    ins {
      @include fadeInAnimation;

      animation-delay: 1s;
    }

    &--hidden {
      display: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;

    iframe {
      align-self: center;
    }
  }

  &--160x600 {
    min-height: 754px;
    min-width: 300px;
    max-width: 300px;
  }

  &--300x250 {
    min-height: 390px;
    max-width: 300px;
  }

  &--300x600 {
    min-height: 754px;
    max-width: 300px;
  }

  &--728x90 {
    min-height: 244px;
    max-width: 728px;
  }

  &__loader {
    background-color: $color-white;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__title {
    font-size: 24px;
    text-align: left;
    line-height: 25px;
    margin-bottom: 24px;

    h4 {
      font-size: 24px;
      line-height: 31px;
      display: inline-block;
    }
  }

  &__content {
    padding: 14px 16px;

    @include fadeInAnimation;

    animation-delay: 0.5s;
  }

  &__header-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__zip {
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: $color-primary-variation-1;
  }

  .zip__text {
    font-size: 16px;
    line-height: 16px;
  }

  &__share {
    position: relative;
  }
}

// This prevents the view error for size with the long width
@media screen and (max-width: 1190px) {
  .ad {
    &--728x90 {
      &.ad__container {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      .ad__unit-preview {
        display: block;
      }

      .ad__unit,
      .ad__unit-preview {
        width: 100%;
        overflow-x: scroll;
        justify-content: flex-start;
        padding-bottom: 3px;

        &::-webkit-scrollbar {
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-tertiary;
          border-radius: 4px;
        }
      }
    }
  }
}
