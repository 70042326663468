@mixin ad-info-button-decoration {
  content: '';
  width: 7px;
  height: 1px;
  position: absolute;
  background-color: $color-black;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transition: transform 0.3s, background-color 0.3s;
}
