.loader {
  width: 100%;
  display: block;

  &__rect-indicator {
    width: 100px;
    display: block;
  }

  &__rect-image {
    width: 100%;
    display: block;
  }

  &__rect-title {
    width: 33%;
    display: block;
  }

  &__rect-subtitle {
    width: 50%;
    display: block;
  }

  &__rect-text {
    width: calc(100% - 30px);
    display: block;
  }
}
