@import '../../../style/variables.scss';
@import '../../../style/mixins.scss';

.ads-container {
  position: relative;

  &__title {
    font-size: 20px;
    line-height: 26px;
    min-height: 32px;
    color: $color-quaternary;
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  &__header {
    padding: 28px 34px;
    min-height: 88px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $color-white;
    transition: box-shadow 0.3s, height 0.3s;

    &-box-shadow {
      box-shadow: 0 4px 9px 0 rgba(81, 81, 81, 0.2);
    }
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
  }

  &__results {
    font-family: $font-family-secondary;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 35px;
    padding: 40px 34px 51px;
  }
}

@media screen and (min-width: $mobile-large-breakpoint) and (max-width: 950px) {
  .ads-container {
    &__header {
      flex-direction: column;
      align-items: self-start;
    }
  }
}

@media screen and (max-width: $mobile-large-breakpoint) {
  .ads-container {
    padding-bottom: 90px;

    &__header {
      padding: 10px 31px;
      min-height: 50px;
    }

    &__container {
      justify-content: center;
    }
  }
}

@media screen and (max-width: $mobile-small-breakpoint) {
  .ads-container {
    &__header {
      padding: 10px 13px;
    }
  }
}
