@import '../../../style/variables.scss';
@import '../../../style/mixins.scss';

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__title {
    font-size: 38px;
    line-height: 49px;
    text-align: center;
    font-weight: 700;
  }

  &__text {
    color: $color-primary-variation-2;
    font-family: $font-family-secondary;
    font-size: 30px;
    line-height: 29px;
    margin-top: 14px;
    text-align: center;
  }

  .set-filters-btn {
    display: none;
  }
}

@media screen and (max-width: $mobile-large-breakpoint) {
  .landing-page {
    .set-filters-btn {
      transition: background-color 0.3s;
      background: $color-primary;
      border-radius: 9px;
      box-shadow: none;
      width: 242px;
      height: 50px;
      margin-top: 40px;
      pointer-events: all;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__text {
        color: $color-white;
        font-size: 18px;
      }

      &__icon {
        @include side-menu-btn-filters;

        margin-right: 11px;

        &::before,
        &::after {
          content: none;
        }
      }

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $color-secondary;
        }
      }
    }
  }
}
