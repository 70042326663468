@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.main__layout {
  display: flex;
  position: fixed;
  justify-content: center;
  top: 99px;
  width: 100%;

  @include fadeInAnimation;

  animation-delay: 1.1s;

  &--landscape-mobile {
    position: relative;
    top: 0;
  }
}
