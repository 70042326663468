@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.main-ads {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $color-cream;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: $color-white-variation-1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-variation-2;
  }

  /* Desktop with mouse or touch pad */
  @media (hover: hover) and (pointer: fine) {
    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-secondary;
    }
  }

  &__home {
    overflow: hidden;
    opacity: 0;

    @include fadeInAnimation;

    animation-delay: 2s;
    justify-content: center;
    padding: 51px 26px;
  }

  header {
    @include fadeInAnimation;

    animation-delay: 0.3s;
  }
}

@media screen and (min-width: $mobile-large-breakpoint) and (max-width: 950px) {
  .main-ads {
    &__side-menu-open {
      .main-ads__header {
        flex-direction: row;
        align-items: center;
      }

      .main-ads__title {
        margin-bottom: 0;
      }
    }
  }
}
