@import '../../style/variables.scss';

.dropdown {
  position: relative;
  user-select: none;
  font-family: $font-family-secondary;

  &__btn {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    padding: 15px 48px 15px 16px;
    background-color: $color-primary-variation-1;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
    color: $color-white-variation-1;
    border-radius: 4px;
    border: 1px solid $color-primary-variation-1;

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &:focus-visible {
        outline: none;
      }

      &:hover,
      &:focus {
        background-color: $color-primary-variation-2;
      }
    }

    &::before {
      content: '';
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgBrc7ZDcAgCIBh7ETdqKPIKG5KMaVHmqAc/k9efBGAI6Idkj0GLypdHRCsz4pRN94XOW8RVGaabMt9iPRmRj8/6+H/0oUOMS9qwqyoC5uhIUxDU5iC5jAFRViRoGh5ewIpuwKwXIwYIQAAAABJRU5ErkJggg==');
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 10px;
      height: 6px;
      right: 19px;
    }

    &--error {
      border: 1px solid $color-red-error;
    }
  }

  &__items {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 48px;
    box-shadow: 0 2px 6px $color-black;
    background-color: $color-primary-variation-1;
    opacity: 0;
    visibility: hidden;
    max-height: 410px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: $color-primary;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary-variation-2;
      transition: background-color 0.3s;
    }

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &::-webkit-scrollbar-thumb:hover {
        background-color: $color-secondary;
      }
    }
  }

  &__item {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    padding: 15px 48px 15px 16px;
    cursor: pointer;
    color: $color-white-variation-1;
    border-radius: 4px;
    background-color: $color-primary-variation-1;

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-image:
          linear-gradient(
            90deg,
            rgba(67, 122, 245, 0.7) 0%,
            rgba(97, 153, 255, 0.7) 100%
          );
      }
    }
  }
}
