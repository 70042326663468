@import '../../../style/variables.scss';

.indicator {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: $color-primary-variation-2;
  background-color: $color-tertiary-variation-1;
  border-radius: 4px;
  padding: 4px 6px;

  &__main {
    position: relative;
  }

  &--published {
    color: $color-primary;
    background-color: $color-green-variation-2;
  }

  &--preview-active {
    color: $color-blue;
    background-color: $color-blue-variation-1;
  }

  &__hover {
    position: absolute;
    transform: translate(-50%, -48px);
    left: 50%;
    padding: 2px 14px;
    background-color: rgba(231, 233, 235, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.16px;
    border-radius: 2px;
    opacity: 0;
    white-space: nowrap;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgb(231, 233, 235);
      position: absolute;
      transform: translate(0, 13px);
    }
  }

  /* Desktop with mouse or touch pad */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & + .indicator__hover {
        opacity: 1;
      }
    }
  }
}
