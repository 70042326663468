@import '../../style/variables.scss';

.logo {
  display: flex;
  align-items: center;

  &__img {
    width: 189px;
    height: 42px;

    & img {
      width: 100%;
    }
  }

  &__content {
    padding-left: 36px;
    margin-left: 36px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 2px;
      height: 32px;
      background-color: $color-white;
    }
  }

  &__title {
    color: $color-white;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  &__version {
    letter-spacing: 0.2px;
  }
}

@media screen and (min-width: $mobile-medium-breakpoint) and (max-width: $header-breakpoint) {
  .logo {
    &__img {
      width: 150px;
      height: 33px;
    }

    &__content {
      padding-left: 26px;
      margin-left: 26px;
    }

    &__title {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media screen and (min-width: $mobile-medium-breakpoint) and (max-width: $mobile-large-breakpoint) {
  .logo {
    &__title {
      max-width: 150px;
    }
  }
}

@media screen and (max-width: $mobile-medium-breakpoint) {
  .logo {
    flex-direction: column;

    &__img {
      width: 90px;
      height: 20px;
    }

    &__content {
      margin: 0;
      padding: 0;
      margin-top: 10px;

      &::before {
        content: none;
      }
    }

    &__title {
      font-size: 12px;
      line-height: 14px;
      max-width: none;
    }
  }
}
