@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.form {
  margin: 0 auto;
  min-width: 280px;
  max-width: 328px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
  }

  &__button {
    position: relative;
  }
}

@media screen and (max-width: $mobile-large-breakpoint) {
  .form {
    &__button {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 8px;
      left: 0;
      right: 8px;
      margin: auto;
      background:
        linear-gradient(
          360deg,
          #141414 50%,
          rgba(20, 20, 20, 0) 100%
        );
      visibility: hidden;

      &.side-menu--open {
        @include fadeInTranslateYFromDownAnimation;

        animation-delay: 0.8s;
        animation-duration: 0.5s;
        visibility: visible;
      }
    }
  }
}

@media screen and (max-width: $mobile-small-breakpoint) {
  .form {
    min-width: 100%;
    max-width: 100%;
  }
}
