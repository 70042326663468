@import './variables.scss';

// This anidation is to override MUI Chips component styles
.MuiFormControl-root {
  .MuiInputBase-root {
    background-color: $color-primary-variation-1;
    min-height: 48px;

    &.MuiInputBase-adornedStart {
      padding: 11px 6px;
      max-height: 128px;
    }

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: $color-primary-variation-2;
      }
    }

    .MuiChipsInput-Chip {
      background-color: $color-primary-variation-2;
      border-radius: 24px;
      margin: 4px;
      height: 24px;

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus {
          background-color: $color-secondary-variation-1;
        }
      }
    }

    .MuiChip-label {
      color: $color-white;
      font-size: 12px;
    }

    .MuiChip-deleteIcon {
      background: none;

      path {
        fill: $color-white;
      }
    }

    .MuiInputBase-input {
      color: $color-white;
      height: 24px;
      padding: 0 0 0 8px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    // Container BTN Clear All ZipCodes
    .css-1a3507z {
      position: absolute;
      top: -13px;
      right: 0;

      button:focus {
        background-color: $color-secondary-variation-1;
      }
    }

    .MuiIconButton-root {
      &::after {
        content: 'Clear zip codes';
        position: absolute;
        top: 7px;
        left: -92px;
        font-size: 12px;
        width: 100px;
        color: $color-secondary-variation-2;
        transition: color 0.3s;
      }

      .MuiSvgIcon-root {
        background-color: $color-secondary-variation-2;
        border-radius: 50%;
        transition: background-color 0.3s;
        width: 0.9em;
        height: 0.9em;
      }

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          &::after {
            color: $color-secondary-variation-3;
          }

          .MuiSvgIcon-root {
            background-color: $color-secondary-variation-3;
          }
        }
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: $color-red-error;
    display: flex;
    align-items: center;
    margin: 5px 5px;
    font-size: 12px;
    line-height: 18px;
    font-family: $font-family-primary;
    letter-spacing: 0.7px;

    &::before {
      content: '';
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgBlVLLbQIxEH0zjnLIJaSD7SBRKkA0QDpIUgKnKIdIRhyiXIJEAXw6oAMQDYBoADpgrxxsM/YuYIEXxJNGO/a8NzPeGUIEp3UN6uFdvLocX+CQg8SAIf18jWIuHUW/GRRPxM2QxgrGNkh/rw/CQqTm4tVwEW4D4169mMM5VDoTjUuLG3wSbj94Uq1etniS3H6GjqggxhHANhhEb7gNBEtNvv6uBBiPd9V5uYmLWuJFRczPMUvcyxtpyrD3w6TM2bb8hF4yZuyMSbdyWE86bVV1xf7Pq6Ht53jcnM7fQD4fqIYXjWT1/JjAhwLhIlRelaTIaCNDaO1FgZ9M3ZGlgHouRGYJs12Q1nnM2QGU3Fn0+WY2XgAAAABJRU5ErkJggg==');
      background-size: contain;
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: $color-red-error;
  }
}
