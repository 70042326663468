@import '../../../style/variables.scss';

.last-update {
  font-size: 12px;
  font-weight: 300;
  color: $color-primary-variation-1;
  display: flex;
  align-items: center;
  gap: 4px;

  svg path {
    stroke: $color-primary-variation-1;
  }
}
