@import 'style/variables.scss';

@mixin box {
  position: relative;
  background:
    linear-gradient(
      180deg,
      $color-secondary-variation-1 0%,
      $color-cream 100%
    );
}

@mixin line-horizontal {
  position: absolute;
  background:
    linear-gradient(
      90deg,
      $color-secondary-variation-1 0%,
      $color-secondary-variation-2 100%
    );
}

@mixin line-vertical {
  position: absolute;
  background:
    linear-gradient(
      0deg,
      $color-secondary-variation-1 0%,
      $color-secondary-variation-2 100%
    );
}

@mixin box--published-mode {
  position: relative;
  background: linear-gradient(180deg, #42be65 0%, #e3ffeb 100%);
}

@mixin line-horizontal--published-mode {
  position: absolute;
  background: linear-gradient(-90deg, #cdffdb 0%, #42be65 100%);
}

@mixin line-vertical--published-mode {
  position: absolute;
  background: linear-gradient(180deg, #cdffdb 0%, #42be65 100%);
}
