@import '../../style/variables.scss';

.error {
  &__main {
    display: flex;
    align-items: center;
    margin-top: 4px;
    animation: inAnimation 0.3s;
  }

  &__icon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgBlVLLbQIxEH0zjnLIJaSD7SBRKkA0QDpIUgKnKIdIRhyiXIJEAXw6oAMQDYBoADpgrxxsM/YuYIEXxJNGO/a8NzPeGUIEp3UN6uFdvLocX+CQg8SAIf18jWIuHUW/GRRPxM2QxgrGNkh/rw/CQqTm4tVwEW4D4169mMM5VDoTjUuLG3wSbj94Uq1etniS3H6GjqggxhHANhhEb7gNBEtNvv6uBBiPd9V5uYmLWuJFRczPMUvcyxtpyrD3w6TM2bb8hF4yZuyMSbdyWE86bVV1xf7Pq6Ht53jcnM7fQD4fqIYXjWT1/JjAhwLhIlRelaTIaCNDaO1FgZ9M3ZGlgHouRGYJs12Q1nnM2QGU3Fn0+WY2XgAAAABJRU5ErkJggg==');
    background-size: contain;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  &__text {
    color: $color-red-error;
    font-size: 12px;
    line-height: 18px;
    font-family: $font-family-primary;
    letter-spacing: 0.7px;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
    height: 18px;
  }
}
