@import '../../style/variables.scss';

.popup {
  background-color: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &__container {
    background-color: $color-white;
    border: 1px solid $color-tertiary-variation-2;
    border-radius: 8px;
    min-height: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    position: relative;

    &--size-large {
      width: 90%;
    }

    &--size-medium {
      width: 80%;
    }

    &--size-small {
      width: 544px;
    }
  }

  &__icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    flex: none;
    margin-right: 16px;

    &--top {
      align-self: flex-start;
    }

    &--center {
      align-self: center;
    }
  }

  &__text {
    color: $color-primary;
    font-size: 14px;
    line-height: 22px;
  }

  &__btn-close {
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-tertiary-variation-1;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    &--absolute {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &--static {
      flex-shrink: 0;
      align-self: flex-start;
    }

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      & {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $mobile-medium-breakpoint) {
    &__container {
      gap: 25px;
      padding: 20px 24px 5px;

      &--size-large,
      &--size-medium,
      &--size-small {
        width: 90%;
      }
    }

    &__icon {
      margin: initial;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &__btn-close {
      position: absolute;
      top: 21px;
      right: 21px;
    }
  }
}
