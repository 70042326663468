@import '../../style/variables.scss';
@import './utils.scss';

.ad-info {
  padding-top: 12px;
  position: relative;

  &::before {
    content: '';
    width: calc(100% + 12px);
    height: 1px;
    background-color: #c7c7c7;
    position: absolute;
    top: 0;
    left: -6px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 24px;
  }

  &__headline {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  &__button {
    border-radius: 50%;
    background: #eceeef;
    width: 23px;
    height: 23px;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s, background-color 0.3s;

    &::before {
      @include ad-info-button-decoration;
    }

    &::after {
      @include ad-info-button-decoration;

      transform: rotate(90deg);
    }

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: rotate(180deg);
      }
    }

    &--open {
      background-color: #000;

      &::before {
        transform: rotate(45deg);
        background-color: #fff;
      }

      &::after {
        transform: rotate(-45deg);
        background-color: #fff;
      }
    }
  }

  &__content {
    height: 0;
    overflow: hidden;
  }

  &__default {
    display: flex;
  }

  .default__icon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ+SURBVHgBxZcxbBMxFIZ/24mgDOQilpYEuI2pEJiRSFkZmpGNVCpIsFRsbKEsMMJUqamUMrAHwYqSIsTarEycEJHKgC4Q2goa2zxf2zRwV+IrIfcNd5c7+/3v2c/2C4MlfnWymAK/qjUKGiiAMXfgc4tp7WnwppK9F9k7G56NTTasgREVEBUSLMISMtqky+rJ+fazIe0OEV2adEVK1OKIhoxrXZdS3TtsJHjUy+8ruZs8Jdb/RdigGSvxtGj4K1OlqO+hyLvV0xUF9gAjhjGU/5yG38S7y/kFxfQT/CcoqJnsrU/NkLiZYzPU9OjYGHq1vo2v2xrnTglcOX8MlnRUT17az4H+nHMhGrbChqXXm7hb8/H83RZi4Jgk7muai7+cK9MYuBgDJon9ar7YF+cMCxgjAroS6FLUBZgda4wE0dcch9NTEUnwc6LMBccsEoCEL3I6KFwkgdYFPq4sD8GYw5EgCYtreEgAyjUvxcBaGtpFTKbzKWMCF86kcRTolPNSUus12uFKcTs/vmF9DESLU7XDoWQdCSB35Bo3x1tQc40Tqu+MbpDtEmwRMXn0sovp+5+De1zUjlzc9WGPb9Vcw7Zm+/hFkvDGwe+nU8icsFy1FHVmvj1nHvs9ZE+aFx2b/pkJhrNUwRjM3VoY+sN+1Lt+DGAOeVr4DRszJvq373/geuG4rbivNK5lb7dbkeJBC6pqaOnVMFpCwoaQy9Rg1VSZtAV5GAWMhjpCOFI8cIDKWyXVjAL++ndnCJrGtabE1uUo4cCvYRZMHjCquZjdStB7lyYpPxys0Y8k3neC6noIQY6wWRpKh6bFPbDCfNqrW0rhDdKb9excx2rV/AKF7+sFETG1CQAAAABJRU5ErkJggg==');
    background-size: contain;
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}
