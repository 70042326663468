@import '../../style/variables.scss';

.dropdown__item {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  padding: 15px 48px 15px 16px;
  cursor: pointer;
  color: $color-white-variation-1;
  border-radius: 4px;
  background-color: $color-primary-variation-1;

  /* Desktop with mouse or touch pad */
  @media (hover: hover) and (pointer: fine) {
    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      background-image:
        linear-gradient(
          90deg,
          rgba(67, 122, 245, 0.7) 0%,
          rgba(97, 153, 255, 0.7) 100%
        );
    }

    &--published-mode:hover,
    &--published-mode:focus {
      background-image:
        linear-gradient(
          90deg,
          rgba(36, 161, 72, 0.7) 0%,
          rgba(138, 239, 167, 0.7) 100%
        );
    }
  }
}
