@import 'style/variables.scss';
@import './mixins.scss';

.squares {
  position: absolute;
  margin: auto;
  top: 280px;
  bottom: 0;
  right: 0;
  left: -60px;
  width: 0;
  height: 0;
  transition: top 1s;

  &__item1 {
    position: absolute;
    top: 69px;
  }

  &__box1 {
    @include box;

    width: 59px;
    height: 60px;
  }

  &__line1 {
    @include line-horizontal;

    width: 1070px;
    height: 2px;
    bottom: 0;
    top: 0;
    margin: auto;
    right: 60px;
  }

  &__line1a {
    @include line-vertical;

    height: 1000px;
    width: 2px;
    left: -220px;
    top: 31px;
  }

  &__item2 {
    position: absolute;
    left: 174px;
  }

  &__box2 {
    @include box;

    width: 88px;
    height: 79px;
  }

  &__line2 {
    @include line-vertical;

    height: 1000px;
    width: 2px;
    left: 0;
    right: 0;
    margin: auto;
    top: 79px;
  }

  &__item3 {
    position: absolute;
    top: 193px;
    left: 302px;
  }

  &__box3 {
    @include box;

    width: 45px;
    height: 46px;
  }

  &__line3 {
    border: dashed 1px $color-secondary-variation-3;
    position: absolute;
    width: 900px;
    height: 2px;
    bottom: 0;
    top: 0;
    margin: auto;
    left: 45px;
  }

  &--published-mode {
    .squares {
      &__box1,
      &__box2,
      &__box3 {
        @include box--published-mode;
      }

      &__line1 {
        @include line-horizontal--published-mode;
      }

      &__line1a {
        @include line-vertical--published-mode;
      }

      &__line2 {
        @include line-vertical--published-mode;
      }

      &__line3 {
        border: dashed 1px $color-green-variation-2;
      }
    }
  }

  @media screen and (max-height: 1000px) {
    top: 200px;
  }

  @media screen and (max-height: 800px) {
    top: 120px;
  }
}
