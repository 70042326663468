@import '../../style/mixins.scss';
@import '../../style/variables.scss';

.btn {
  &__container {
    display: flex;
    align-items: center;
    position: relative;

    @include fadeInAnimation;

    animation-delay: 0.5s;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 9px;
    background-color: rgba(230, 231, 233, 1);
    position: relative;
    overflow: hidden;
  }

  &__info {
    background: transparent;
    cursor: pointer;
    position: relative;
    border-radius: 9px;
    padding: 6px 13px;
  }

  &__bar {
    border-radius: 7px;
    background: #fff;
    transition: transform 0.3s;
    position: absolute;
    border: 2px solid rgba(230, 231, 233, 1);
    width: 50%;
    height: 100%;
  }

  &__label-mobile {
    font-family: $font-family-secondary;
    margin-right: 10px;
    text-align: right;
    display: none;
  }

  &__text {
    display: block;
    pointer-events: none;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 20px;
  }
}

@media screen and (max-width: $mobile-large-breakpoint) {
  .btn {
    &__content {
      width: 52px;
      height: 32px;
      padding: 4px;
      transition: background-color 0.3s;
      border-radius: 20px;

      &--show-state {
        background-color: $color-secondary-variation-2;
      }
    }

    &__bar {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05), 0 3px 1px rgba(0, 0, 0, 0.04);
      width: 24px;
      height: 24px;
      border-radius: 20px;
      transform: translateX(calc(100% - 4px));
      border: none;

      &--show {
        transform: translateX(0);
      }
    }

    &__info {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &__label-mobile {
      font-size: 14px;
      line-height: 16px;
      display: block;
    }

    &__info--hide {
      display: none;
    }

    &__text {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-small-breakpoint) {
  .btn {
    &__label-mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
