@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.success-message {
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: $color-secondary-variation-1;
  top: 0;
  left: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  border: 1px solid $color-secondary-variation-1;

  &__loader {
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid $color-white;
    border-color: $color-white transparent $color-white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  &__text {
    font-size: 12px;
    color: $color-white;
    letter-spacing: 0.5px;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
