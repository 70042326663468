@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.side-menu {
  position: relative;
  flex-basis: 377px;
  background-color: $color-primary;
  overflow: hidden;
  transition: flex-basis 0.3s;
  z-index: 2;

  &__container {
    position: relative;
    padding: 29px 45px 51px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 377px;
    transition: opacity 0.3s, visibility 0.3s linear, transform 0.3s;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: $color-primary;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary-variation-2;
      transition: background-color 0.3s;
    }

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &::-webkit-scrollbar-thumb:hover {
        background-color: $color-secondary;
      }
    }
  }

  &__title,
  &__title--mobile {
    color: $color-white;
    margin: 0 0 32px;
    text-align: left;
    line-height: 31px;
    font-size: 24px;
  }

  &__title {
    display: block;
  }

  &__btn {
    position: fixed;
    left: 363px;
    top: 130px;
    width: 28px;
    height: 28px;
    transition: left 0.3s;
    z-index: 1;
    opacity: 0;
  }

  .btn--open {
    background: linear-gradient(135.71deg, #1477f8 11.66%, #61bef2 83.99%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .btn__icon {
      @include side-menu-btn-arrow;

      transform: translateX(-1px);

      &::before,
      &::after {
        content: '';

        @include side-menu-btn-arrow;

        position: absolute;
        transition: transform 0.3s;
      }
    }
  }

  &--published-mode .btn--open {
    background: linear-gradient(135.71deg, #24a148 11.66%, #8feca9 83.99%);
  }

  &__header--mobile,
  .btn__text {
    display: none;
  }
}

@media screen and (max-width: $mobile-small-breakpoint) {
  .side-menu {
    &__container {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

@media screen and (min-width: ($mobile-large-breakpoint + 1)) {
  .side-menu {
    &__btn {
      opacity: 0;

      @include fadeInAnimation;

      animation-delay: 0.5s;
      animation-duration: 0.5s;

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus-visible {
          .btn--open {
            outline: none;
            transform: scale(1.3);

            .btn__icon {
              &::before {
                transform: translate(-6px, 0);
              }

              &::after {
                transform: translate(6px, 0);
              }
            }
          }
        }
      }
    }

    &.side-menu--closed {
      flex-basis: 69px;

      .side-menu__container {
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transform: translateX(20px);
      }

      .side-menu__btn {
        left: 55px;

        .btn--open {
          .btn__icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-large-breakpoint) {
  .side-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: bottom 0.5s;
    opacity: 0;

    @include fadeInAnimation;

    animation-delay: 0.5s;
    border-radius: 15px 15px 0 0;
    z-index: 2;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 71px;
      transition: transform 0.3s, opacity 0.3s;
      transition-delay: 0.5s;
      padding-bottom: 100px;
    }

    &__btn {
      bottom: 0;
      left: initial;
      top: initial;
      transition: bottom 0.3s;
      width: 100%;
      background:
        linear-gradient(
          360deg,
          #f7f9fa -2.48%,
          rgba(247, 249, 250, 0) 100%
        );
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      cursor: default;
    }

    &__title {
      display: none;
    }

    .btn--open {
      transition: background-color 0.3s;
      background: $color-primary;
      border-radius: 9px;
      box-shadow: none;
      width: 146px;
      height: 40px;
      visibility: hidden;

      .btn__text {
        display: block;
        color: $color-white;
        font-size: 14px;
      }

      .btn__icon {
        @include side-menu-btn-filters;

        margin-right: 11px;

        &::before,
        &::after {
          content: none;
        }
      }
    }

    &__header--mobile {
      display: block;
      position: absolute;
      top: 0;
      left: 13px;
      z-index: 1;
      width: calc(100% - 26px);
      text-align: center;
      padding-top: 20px;
      height: 70px;
      background:
        linear-gradient(
          180deg,
          #141414 50%,
          rgba(20, 20, 20, 0) 100%
        );
    }

    .btn--close {
      background: linear-gradient(135.71deg, #1477f8 11.66%, #61bef2 83.99%);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 20px;

      .btn__icon {
        @include side-menu-btn-close;

        display: block;
        position: absolute;
        left: 11px;
        top: 11px;
      }
    }

    &--published-mode .btn--close {
      background: linear-gradient(135.71deg, #24a148 11.66%, #8feca9 83.99%);
    }

    &__title--mobile {
      text-align: center;
    }

    &.side-menu--closed {
      bottom: -100%;

      .side-menu__container {
        transform: translateY(20px);
        opacity: 0;
      }

      .side-menu__btn {
        @include fadeInTranslateYFromDownAnimation;

        animation-delay: 0.6s;
        animation-duration: 0.5s;

        &--enable {
          .btn--open {
            pointer-events: all;
            visibility: visible;

            /* Desktop with mouse or touch pad */
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: $color-secondary;
              }
            }
          }
        }
      }
    }

    &.side-menu--open {
      .btn--close {
        @include fadeInTranslateYFromDownAnimation;

        animation-delay: 0.5s;
        animation-duration: 0.5s;
      }
    }
  }
}
