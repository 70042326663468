@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__title {
    font-size: 38px;
    line-height: 49px;
    text-align: center;
    font-weight: 700;
  }

  &__text {
    color: $color-primary-variation-2;
    font-family: $font-family-secondary;
    font-size: 30px;
    line-height: 29px;
    margin-top: 14px;
    text-align: center;
  }
}
