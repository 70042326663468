@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.huge-mode {
  &__main {
    font-family: $font-family-secondary;
  }

  &__modal-header,
  &__modal-action {
    display: flex;
  }

  &__modal-action {
    justify-content: space-between;
    margin-top: 32px;
  }

  &__checkbox {
    display: flex;
    align-items: center;

    input {
      margin-right: 8px;

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        & {
          cursor: pointer;
        }
      }
    }

    label {
      font-size: 14px;

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        & {
          cursor: pointer;
        }
      }
    }
  }

  &__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .btn {
      font-size: 18px;
      padding: 10px 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      transition: background-color 0.3s;
      cursor: pointer;

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }

      &--blue {
        background-color: $color-secondary;
        color: $color-white;
      }

      &--white {
        background-color: $color-white;
        color: $color-primary;
        border: rgba(208, 213, 221, 1) 1px solid;
      }

      /* Desktop with mouse or touch pad */
      @media (hover: hover) and (pointer: fine) {
        &:focus {
          background-color: $color-secondary-variation-1;
        }

        &:hover {
          background-color: $color-secondary-variation-1;
        }

        &--white:focus,
        &--white:hover {
          color: $color-white;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-medium-breakpoint) {
    &__modal-action {
      flex-direction: column;
      gap: 20px;
      margin-top: 15px;
    }

    &__checkbox {
      label {
        font-size: 12px;
        font-weight: 300;
        line-height: 11.35px;
      }
    }

    &__text {
      h2 {
        align-self: center;
        max-width: 60%;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 33px;
      }

      p {
        font-size: 13px;
        line-height: 15.3px;
        margin-bottom: 16px;
      }
    }

    &__btns {
      gap: 8px;

      .btn {
        p {
          font-size: 12px;
          line-height: 13px;
        }
      }
    }
  }
}
