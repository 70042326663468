@import '../../style/variables.scss';

.information-banner {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: min-content min-content 1fr min-content min-content;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding-left: 16px;
  font-family: $font-family-secondary;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  background-color: $color-green-variation-3;
  border: solid 0.5px $color-green-variation-1;
  border-left: solid 4px $color-green;

  & * {
    display: flex;
    align-items: center;
  }

  svg {
    align-self: center;
    flex-shrink: 0;

    path {
      fill: $color-green;
    }
  }

  &__display-mode {
    flex: 1 1 auto;
    margin: 0 16px;
    white-space: nowrap;
    font-weight: 500;
  }

  &__content {
    gap: 16px;
    flex: 1 1 auto;
    margin-right: 16px;
    padding: 16px 0;
  }

  &__go-back {
    white-space: nowrap;
    padding: 16px;
    cursor: pointer;
    color: $color-secondary;
    transition: color 0.2s ease;

    &:hover {
      color: $color-secondary-variation-1;
    }
  }

  &--vertical {
    grid-template-rows: 48px 1fr;
    grid-template-columns: min-content 1fr min-content min-content;

    svg {
      /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area */
      grid-area: 1 / 1 / 2 / 2;
    }

    .information-banner__content {
      order: 1;
      grid-area: 2 / 2 / 3 / 4;
      margin: 0 16px;
      padding-top: 0;
    }
  }
}
