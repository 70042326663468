@import '../../style/variables.scss';

.button {
  color: white;
  font-size: 18px;
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: background-color 0.3s;
  background-color: $color-secondary;
  cursor: pointer;

  &.button--published-mode {
    background-color: $color-green-variation-4;

    /* Desktop with mouse or touch pad */
    @media (hover: hover) and (pointer: fine) {
      &:focus {
        background-color: $color-green-variation-1;
      }

      &:hover {
        background-color: $color-green-variation-1;
      }
    }
  }

  /* Desktop with mouse or touch pad */
  @media (hover: hover) and (pointer: fine) {
    &:focus {
      background-color: $color-secondary-variation-1;
    }

    &:hover {
      background-color: $color-secondary-variation-1;
    }
  }
}

@media screen and (max-width: $mobile-large-breakpoint) {
  .button {
    max-width: 326px;
  }
}

@media screen and (max-width: $mobile-small-breakpoint) {
  .button {
    max-width: calc(100% - 32px);
  }
}
