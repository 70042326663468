@import './variables.scss';
@import './chips.scss';

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family-primary;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}

h1,
h2,
h3 {
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}
