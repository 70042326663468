@import '../../style/variables.scss';

// This anidation is to override Chips component styles
.chips-input {
  position: relative;

  &__container {
    border-radius: 4px;
    border: 1px solid $color-primary;
    transition: border 0.3s;
  }

  &__field {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    padding: 15px 48px 15px 16px;
    color: $color-white-variation-1;
    border-radius: 4px;
    transition: background-color 0.3s;
    width: 100%;

    &--error {
      border: 1px solid $color-red-error;
      border-radius: 4px;
    }
  }

  &__input--hide {
    opacity: 0;
    visibility: hidden;
  }
}
