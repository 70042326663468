@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animate($animation, $duration) {
  animation-name: $animation;
  animation-duration: $duration;
  animation-fill-mode: forwards;
}
