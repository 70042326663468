@import '../../style/variables.scss';
@import '../../style/mixins.scss';

.header {
  background-color: $color-primary;
  height: 99px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;

  @include fadeInAnimation;

  animation-delay: 1s;

  &__items {
    padding: 21px 45px;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__subtitle,
  &__published-mode {
    font-family: $font-family-secondary;
    color: $color-white;
    font-size: 12px;
  }

  &__user-guide,
  &__published-mode {
    font-family: $font-family-secondary;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid $color-white;
    text-align: right;
  }

  &__hide-link {
    cursor: default;
    position: absolute;
    color: transparent;
    transform: translate(-20px, -10px);
    height: 10px;
    width: 20px;
  }

  &__published-mode,
  &__link {
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      width: 16px;
      height: 16px;
      display: inline-block;
      background-size: contain;
      margin-right: 4px;
    }
  }

  &__published-mode {
    i {
      background-image: url('/assets/published-mode-icon.png');
    }
  }

  &__link {
    i {
      background-image: url('/assets/user-guide-icon.png');
    }

    span {
      font-size: 12px;
    }
  }

  &__content {
    display: flex;
  }

  &--landscape-mobile {
    position: relative;
  }

  &__loader {
    background: $color-secondary;
    background:
      linear-gradient(
        90deg,
        rgba(20, 119, 248, 1) 0%,
        rgba(96, 158, 255, 1) 25%,
        rgba(97, 190, 242, 1) 50%,
        rgba(152, 218, 255, 1) 75%,
        rgba(148, 216, 255, 1) 100%
      );
    transition: all 0.3s;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0;

    &--alfa-romeo {
      background:
        linear-gradient(
          90deg,
          #2864c1 0%,
          #609eff 25%,
          #1c7253 50%,
          #7c1727 75%,
          #7c1727 100%
        );
    }

    &--chrysler {
      background:
        linear-gradient(
          90deg,
          #1477f8 0%,
          #609eff 25%,
          #61bef2 50%,
          #98daff 75%,
          #bae1f7 100%
        );
    }

    &--dodge {
      background:
        linear-gradient(
          90deg,
          #2864c1 0%,
          #609eff 25%,
          #f2616a 50%,
          #ff98b7 75%,
          #f7bac8 100%
        );
    }

    &--jeep {
      background:
        linear-gradient(
          90deg,
          #2864c1 0%,
          #609eff 25%,
          #f2a761 50%,
          #ffd698 75%,
          #f7dbba 100%
        );
    }

    &--ram {
      background:
        linear-gradient(
          90deg,
          #2864c1 0%,
          #609eff 25%,
          #7c5151 50%,
          #d93831 75%,
          #a43535 100%
        );
    }

    &--fiat {
      background:
        linear-gradient(
          90deg,
          #2864c1 0%,
          #609eff 25%,
          #e9000c 50%,
          #f13d47 75%,
          #fdabaf 100%
        );
    }
  }
}

@media screen and (max-width: $mobile-medium-breakpoint) {
  .header {
    &__items {
      padding: 18px 18px 14px;
    }

    &__item {
      flex-direction: column;
    }
  }
}
